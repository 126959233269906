.server-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0.5rem 0.5rem;

  & > .summary {
    margin: 0.5rem;
    flex: 1;

    & > .name {
      font-weight: bold;
      white-space: nowrap;
    }
  }

  & > .button {
    margin: 0.5rem;
    flex: 0;
  }
}
