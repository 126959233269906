.nav-bar {
  height: 3rem;
  background: white;
  box-shadow: 0 1.5px 3px $neutral-8;
  display: flex;

  > .app-title {
    flex: 1 1 auto;
    margin-top: 0.3rem;
    margin-left: 0.6rem;
  }
  .nav-icon {
    width: 2rem;
    height: 2rem;
    padding: 0.5rem 0.9rem;
    position: relative;
  }
}

.app-title {
  display: inline-block;

  // I would love to make .subject use text-overflow: ellipsis but after about
  // 1hr of trying every combination of flexbox under the sun I couldn't get the
  // h2 to be independently resizeable so that it would work.
  overflow: hidden;

  > .appname {
    display: inline-block;
    font-size: 2rem;
    margin: 0;
  }
  > .subject {
    display: inline-block;
    font-size: 1.2rem;
    font-weight: normal;
    color: $neutral-6;
    margin: 0;
    margin-left: 0.6rem;
  }
}

.nav-icon {
  // We put the icon itself in a pseudo-element so we can change the
  // background-color independently
  &::before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  // Common variants
  &.-active {
    background: $highlight-5;
  }
  &.-selectable:hover {
    background: $highlight-7;
  }

  // Sync icon
  &.-sync::before {
    content: "";
    background: url('img/sync-icons.svg') 0 / 1000% 100% content-box;
  }
  &.-sync.-inprogress::before {
    animation: rotate 1s linear infinite;
  }

  // The little icon overlay indicating the sync state
  &.-sync .overlay {
    position: absolute;
    left: 2rem;
    top: 1.5rem;
    width: 1.1rem;
    height: 1.1rem;
  }
  &.-sync.-paused .overlay {
    background: url("img/sync-icons.svg") -400% 0/1000% 100% content-box
  }
  &.-sync.-offline .overlay {
    background: url("img/sync-icons.svg") -300% 0/1000% 100% content-box
  }
  &.-sync.-error .overlay {
    background: url("img/sync-icons.svg") -200% 0/1000% 100% content-box
  }

  // Settings menu
  &.-settings::before {
    background: url('img/icons.svg') -1400% -200% / #{($icon-columns * 100%) ($icon-rows * 100%)} content-box;
  }
}

@keyframes rotate {
  from { transform: rotate(0turn); }
  to   { transform: rotate(1turn); }
}
