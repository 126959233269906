@mixin rich-text {
  .bold {
    font-weight: bold;
  }

  .italic {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .dotemphasis {
    text-emphasis: dot;
    -webkit-text-emphasis: dot;
  }

  .green {
    color: var(--text-green);
  }
  .blue {
    color: var(--text-blue);
  }
  .purple {
    color: var(--text-purple);
  }
  .red {
    color: var(--text-red);
  }
  .orange {
    color: var(--text-orange);
  }
}
