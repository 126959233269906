.cardface-input {
  @include vertical-center;

  > .DraftEditor-root {
    // Make the text area fill the component height
    flex: 1;

    @include vertical-center;

    > .DraftEditor-editorContainer {
      height: 100%;
    }

    > .public-DraftEditorPlaceholder-root {
      color: $neutral-8;
      transform: translateY(-2em);
      text-transform: uppercase;
    }

    &:hover > .public-DraftEditorPlaceholder-root,
    &:focus-within > .public-DraftEditorPlaceholder-root {
      color: $neutral-7;
    }

    // Vertically center contents
    .public-DraftEditor-content {
      font-size: 1.5rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      box-sizing: border-box;

      @include vertical-center;
    }
  }
}
