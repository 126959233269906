input:not([type]),
input[type=text],
input[type=password],
input[type=search],
textarea {
  @include textbox-styles;
}

input[type=search] {
  // Undo changes from normalize.css
  box-sizing: border-box;
}
