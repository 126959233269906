$highlight-1: hsl(197, 100%, 21%);
$highlight-2: hsl(197, 87%, 29%);
$highlight-3: hsl(197, 82%, 33%);
$highlight-4: hsl(197, 76%, 39%);
$highlight-5: hsl(197, 67%, 45%);
$highlight-6: hsl(197, 65%, 55%);
$highlight-7: hsl(197, 74%, 65%);
$highlight-8: hsl(197, 84%, 74%);
$highlight-9: hsl(197, 97%, 85%);
$highlight-9-5: hsl(197, 83%, 90%);
$highlight-10: hsl(197, 79%, 92%);

$yellow-10: hsl(55, 100%, 99%);
$yellow-9-5: hsl(53.3, 100%, 98%);
$yellow-9: hsl(55.7, 100%, 96%);
$yellow-8: hsl(53.8, 100%, 91.2%);
$yellow-7: hsl(54.8, 90%, 85.1%);
$yellow-6: hsl(53.2, 74%, 74.3%);
$yellow-5: hsl(52.6, 70%, 59.4%);
$yellow-4: hsl(50.9, 68.5%, 47.3%);
$yellow-3: hsl(48.5, 79.9%, 37.1%);
$yellow-2: hsl(45.3, 85.1%, 26.3%);
$yellow-1: hsl(42.3, 85.9%, 13.9%);

$neutral-1: hsl(209, 61%, 16%);
$neutral-2: hsl(211, 39%, 23%);
$neutral-3: hsl(209, 34%, 30%);
$neutral-4: hsl(209, 28%, 39%);
$neutral-5: hsl(210, 22%, 49%);
$neutral-6: hsl(209, 23%, 60%);
$neutral-7: hsl(211, 27%, 70%);
$neutral-8: hsl(210, 31%, 80%);
$neutral-9: hsl(212, 33%, 89%);
$neutral-10: hsl(210, 36%, 96%);

$red-1: hsl(360, 92%, 20%);
$red-2: hsl(360, 85%, 25%);
$red-3: hsl(360, 79%, 32%);
$red-4: hsl(360, 72%, 38%);
$red-5: hsl(360, 67%, 44%);
$red-6: hsl(360, 64%, 55%);
$red-7: hsl(360, 71%, 66%);
$red-8: hsl(360, 77%, 78%);
$red-9: hsl(360, 82%, 89%);
$red-10: hsl(360, 100%, 97%);

$green-1: hsl(125, 86%, 14%);
$green-2: hsl(125, 73%, 20%);
$green-3: hsl(125, 56%, 29%);
$green-4: hsl(122, 47%, 35%);
$green-5: hsl(122, 39%, 41%);
$green-6: hsl(123, 35%, 51%);
$green-7: hsl(123, 38%, 63%);
$green-8: hsl(122, 42%, 75%);
$green-9: hsl(126, 49%, 84%);
$green-10: hsl(125, 65%, 93%);

$text-color: #323335;

:root {
  // These colors need to be synchronized with the color icons in
  // edit-icons.svg.
  --text-black: #{$text-color};
  --text-green: #4cbd4c;
  --text-blue: #0f5bde;
  --text-purple: #cb3fcb;
  --text-red: #{$red-6};
  --text-orange: orange;
}
