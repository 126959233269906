.save-status {
  font-size: 0.9em;
  // 1.4 is the line-height
  height: 1.4em;
  overflow: hidden;

  > .label {
    display: block;
    text-align: end;
    font-style: italic;
    // The short delay here is to avoid showing one label (typically the
    // in-progress one) for only a short time.
    transition: opacity 0.8s 0.05s;
    opacity: 0;

    &.inprogress {
      transform: translateY(-1.4em);
    }
    &.error {
      color: $red-6;
      transform: translateY(-2.8em);
    }

    &::after {
      content: "";
      display: inline-block;
      position: relative;

      width: 1em;
      height: 1em;
      margin-inline-start: 0.5rem;
      vertical-align: middle;

      background-image: url('img/icons.svg');
      background-position-y: -1em * $icon-row-text-color;
      background-position-x: -22em;
      background-size: $icon-columns+em $icon-rows+em;
      background-repeat: no-repeat;
    }
    &.inprogress::after {
      background-position-x: -21em;
      animation: fade-in 0.6s alternate ease-in-out infinite;
    }
    &.error::after {
      background-position-x: -23em;
      background-position-y: -1em * $icon-row-red-6;
    }
  }

  &.-inprogress > .label.inprogress {
    opacity: 1;
  }

  &.-error > .label.error {
    opacity: 1;
    &[title] {
      text-decoration: underline;
      text-decoration-style: dotted;
    }
  }

  &.-ok > .label.ok {
    animation: fade-ok 2.2s forwards;
  }
  @keyframes fade-ok { 30% { opacity: 1 } 80% { opacity: 1 } }
}
