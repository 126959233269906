.menu-item {
  list-style: none;
  min-width: 180px;

  & > .command {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-inline-end: 15px;
    min-height: 40px;
    padding: 4px 12px;
    outline: none;
    border: none;
    color: $highlight-4;
    background-color: transparent;
    text-align: start;
    width: 100%;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 1rem;
    box-sizing: border-box;

    -moz-user-select: none;
    user-select: none;

    &:hover,
    &:focus, {
      background-color: $highlight-10;
    }

    &:hover:active {
      background-color: $highlight-9;
    }

    &:disabled, &.-disabled {
      pointer-events: none;
      cursor: default;
      color: $neutral-6;
    }

    &.-yellow {
      color: $yellow-4;

      &:hover,
      &:focus, {
        background-color: $yellow-8;
      }

      &:hover:active {
        background-color: $yellow-9;
      }
    }

    &.-iconic > .label::before {
      content: " ";
      display: inline-block;
      margin-inline-end: 0.5em;
      width: 1em;
      height: 1em;
      background-image: url('img/icons.svg');
      background-position-y: -1em * $icon-row-highlight-5;
      background-size: $icon-columns+em $icon-rows+em;
      background-repeat: no-repeat;
      /* Better optical alignment than with 'vertical-align: middle'.
         Works well with font sizes between 12px and 16px. */
      vertical-align: -3px;
    }

    &:disabled.-iconic > .label::before,
    &.-disabled.-iconic > .label::before {
      background-position-y: -1em * $icon-row-neutral-6;
    }

    > .accelerator {
      margin-left: 1em;
      color: $neutral-8;
    }

    &.-yellow {
      &.-iconic > .label::before {
        background-position-y: -1em * $icon-row-yellow-4;;
      }
    }

    &.-edit > .label::before {
      background-position-x: -5em;
    }

    &.-add-reversed > .label::before {
      background-position-x: -24em;
    }

    &.-delete {
      &:not(:disabled) {
        color: $red-6;

        &:hover, &:focus {
          background-color: $red-10;
        }
        &:hover:active {
          background-color: $red-9;
        }
      }

      & > .label::before {
        background-position-x: -3em;
        background-position-y: -1em * $icon-row-red-6;
      }
    }
  }
}
