.cardface-editcontrols {
  // Normally we actually make the artificial selection look no different from
  // regular text and only reveal it when the toolbar is in focus.
  --selection-bg: inherit;
  // Don't allow margin from the children to collapse with any applied to us.
  padding-top: .1px;

  > * {
    box-sizing: border-box;
    width: 100%;
  }

  &.-toolbarfocus {
    --selection-bg: #{$highlight-7};
  }

  ::selection {
    background-color: $highlight-5;
    color: white;
  }

  > .front, > .back {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 1rem;
    min-height: 5rem;

    color: lighten($text-color, 20%);
    &:hover, &:focus-within, &.-targeted {
      color: $text-color;
    }
  }

  > .divider {
    width: 80%;
  }

  > .toolbar {
    margin: .5rem;
  }
}
