// Ideally we'd make the container and the app one and the same but
// DocumentTitle can't accept multiply children so currently they're separate

#container {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;

  & > .screens {
    position: relative;
    flex: 1;
    overflow: hidden;
    // Establish a new stacking context so that position: fixed in any of the
    // screens makes the position relative to the screen position.
    transform: translate(0px);
  }
  & > .screens > * {
    height: 100%;
  }

  & > .tabbar {
    box-sizing: border-box;
    width: 100%;
  }
}
