.review-card {
  position: relative;

  > .front, > .back {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: opacity 0.2s;

    @include card-styles;
    .text {
      @include rich-text;
      p {
        margin-top: 0.1em;
        margin-bottom: 0.1em;
      }
    }
  }

  &:not(.-showback) > .back,
  &.-showback > .front {
    opacity: 0;
    pointer-events: none;
  }

  > .front > .frontregion {
    height: 100%;
  }

  > .front > .frontregion,
  > .back > .backregion {
    &[data-size=x-small] {
      font-size: 12px;
    }
    &[data-size=small] {
      font-size: 24px;
    }
    &[data-size=medium] {
      font-size: 48px;
    }
    &[data-size=large] {
      font-size: 72px;
    }
    &[data-size=x-large] {
      font-size: 96px;
    }

    // If we want padding *inside* the text region, then we need to apply it to
    // the text itself since it won't affect the container bbox so the
    // resizing of the text content will just ignore it.
    > .text {
      padding: 2rem;
    }

    @media screen and (max-width: 600px) {
      &[data-size=x-small] {
        font-size: 10px;
      }
      &[data-size=small] {
        font-size: 16px;
      }
      &[data-size=medium] {
        font-size: 24px;
      }
      &[data-size=large] {
        font-size: 40px;
      }
      &[data-size=x-large] {
        font-size: 60px;
      }

      > .text {
        padding: 1rem;
      }
    }
  }

  > .back {
    > .frontregion {
      height: 33%;

      &[data-size=x-small] {
        font-size: 12px;
      }
      &[data-size=small] {
        font-size: 16px;
      }
      &[data-size=medium] {
        font-size: 22px;
      }
      &[data-size=large] {
        font-size: 30px;
      }
      &[data-size=x-large] {
        font-size: 40px;
      }

      > .text {
        padding: 2rem;
      }

      @media screen and (max-width: 600px) {
        &[data-size=x-small] {
          font-size: 10px;
        }
        &[data-size=small] {
          font-size: 13px;
        }
        &[data-size=medium] {
          font-size: 16px;
        }
        &[data-size=large] {
          font-size: 24px;
        }
        &[data-size=x-large] {
          font-size: 32px;
        }

        > .text {
          padding: 1rem;
        }
      }
    }
    > .divider {
      box-sizing: border-box;
      width: 80%;
    }
    > .backregion {
      height: 66%;
    }
  }
}
