.cancelable-textbox {
  display: inline-block;

  & input[type="text"]::-ms-clear,
  & input[type="search"]::-ms-clear {
    display: none;
  }

  & > .cancel {
    width: 2em;
    margin: 0;
    margin-left: -2em;
    padding-left: 0;
    padding-right: 0;
    border: 0;
    background: transparent;
    transition: transform 0.3s;

    &[aria-hidden=true] {
      transform: scale(0);
    }
    &::after {
      color: $neutral-8;
      content: "X";
      font-weight: bold;
    }
    &:hover::after {
      color: $neutral-6;
    }

    & > .label {
      position: absolute;
      left: -10000px;
    }
  }
}
