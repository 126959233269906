$edit-icons-columns: 12;
$edit-icons-rows: 8;
$edit-icons-size: 16px;

.format-toolbar {
  > .button {
    font-size: 0;
    border-color: transparent;
    display: inline-block;
    border-radius: 4px;
    border-width: 2px;
    opacity: 0.3;
    transition: background-color 0.1s;

    &:hover:not(:disabled) {
      border-color: transparent;
      background: $neutral-10;
      opacity: 1;
      transition-property: none;
      filter: none;
    }
    &:focus {
      border-color: $highlight-5;
      border-style: dotted;
      opacity: 1;
    }
    &:active {
      background: $neutral-9;
      opacity: 1;
    }

    &::before {
      content: "";
      width: $edit-icons-size;
      height: $edit-icons-size;
      padding: .4rem;
      display: inline-block;
      background-image: url('img/edit-icons.svg');
      background-origin: content-box;
      background-clip: content-box;
      background-position-y: -$edit-icons-size;
      background-size: $edit-icons-size * $edit-icons-columns  $edit-icons-size * $edit-icons-rows;
      background-repeat: no-repeat;
    }
    &.italic::before  {
      background-position-x: -1 * $edit-icons-size;
    }
    &.underline::before  {
      background-position-x: -2 * $edit-icons-size;
    }
    &.emphasis::before  {
      background-position-x: -3 * $edit-icons-size;
    }
    &.color::before  {
      padding-right: .1rem;
      background-position-x: -4 * $edit-icons-size;
    }
    // Current color indication
    &.color::after  {
      content: "";
      display: inline-block;
      position: relative;
      // TODO: Switch to inset-inline-start
      left: calc(-#{$edit-icons-size} - .1rem);
      top: -.4rem;
      width: $edit-icons-size;
      height: 3px;
      // TODO: Switch to margin-inline-end
      margin-right: -16px;
      background-color: var(--selected-color);
    }
    &.color-dropdown::before  {
      padding-left: .1rem;
      $icon-scale: 5 / 8;
      background-position-x: -11 * $edit-icons-size * $icon-scale;
      background-position-y: -1 * $edit-icons-size * $icon-scale;
      background-size: $edit-icons-size * $edit-icons-columns * $icon-scale  $edit-icons-size * $edit-icons-rows * $icon-scale;
      width: $icon-scale * $edit-icons-size;
      height: $icon-scale * $edit-icons-size;
    }
    &.cloze::before  {
      background-position-x: -6 * $edit-icons-size;
    }
    &:not(:disabled).cloze.-green::before  {
      background-position-y: -3 * $edit-icons-size;
    }
    &:not(:disabled).cloze.-blue::before  {
      background-position-y: -4 * $edit-icons-size;
    }
    &:not(:disabled).cloze.-purple::before  {
      background-position-y: -5 * $edit-icons-size;
    }
    &:not(:disabled).cloze.-red::before  {
      background-position-y: -6 * $edit-icons-size;
    }
    &:not(:disabled).cloze.-orange::before  {
      background-position-y: -7 * $edit-icons-size;
    }

    &:hover:not(:disabled)::before {
      background-position-y: 0;
    }
  }

  &.-yellow > .button {
    &:hover:not(:disabled) {
      background: $yellow-9-5;
      border-color: transparent;
    }
    &:active, &:focus {
      background: $yellow-9;
      box-shadow: none;
    }
    &:active {
      border-style: dotted;
      background: $yellow-9;
    }
    &:active, &:focus {
      border-color: $yellow-4;
      &:hover:not(:disabled) {
        border-color: $yellow-5;
        background: $yellow-10;
      }
    }

    &::before {
      background-position-y: -2 * $edit-icons-size;
    }
    &.color-dropdown::before  {
      $icon-scale: 5 / 8;
      background-position-y: -2 * $edit-icons-size * $icon-scale;
    }
    &:hover:not(:disabled)::before {
      background-position-y: -2 * $edit-icons-size;
    }
  }

  &.-center {
    display: flex;
    justify-content: center;
  }

  // Set when any of the buttons or connected textfields are focussed
  &.-areafocus {
    > .button:not(:disabled) {
      opacity: 1;
      // Set whenever the button's feature is active (e.g. selected text is
      // bold)
      &.-set::before {
        background-position-y: 0;
      }
    }
  }
}

#speech-bubbles > .format-toolbar-color > .panel {
  padding: .6rem;
}
