.review-screen {
  /*
   * This should be min-height: 100% (not 'height: 100%') but Chrome refuse to
   * fix their flexbox implementation.
   */
  height: 100%;
  background: $neutral-10;

  display: flex;
  flex-direction: column;

  & > .buttons {
    width: 100%;
    text-align: right;
    // Let's do this quirks-mode style
    line-height: 0;
  }
  & > .content {
    /* This should be 'flex: 1 0 auto' but Chrome... :( */
    flex: 1;

    &.summary-panel {
      outline: none;
    }

    &.summary-panel > .details {
      text-align: center;
    }
  }
  & > .progress {
    height: 0.5rem;
    width: 100%;
  }
}
