.stacked-group {
  & > * {
    display: block;
  }

  & input:first-child:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  //
  // Normally the item below will overlap our bottom border, but when we
  // are in focus (and the border color changes) we should be on top so our
  // border shows.
  //
  & input:not(:last-child):focus {
    position: relative;
  }
  & input:not(:first-child):not(:last-child) {
    border-radius: 0;
    margin-top: -$border-width;
  }
  & input:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -$border-width;
  }
}
