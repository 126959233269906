.settings-panel {
  display: flex;
  flex-direction: column;
  /*
   * We do this just to make sure the panel doesn't keep resizing when the
   * contents change. We could just do 'height: 100%' but there's too much
   * empty space on desktop computers in that case. 31rem happens to fit most
   * of the content we currently stick in these panels (and fit in a 320x480
   * screen).
   */
  min-height: 31rem;

  & > .heading {
    margin-top: 0;
    font-size: 1.3rem;
  }
  & > :not(.heading) {
    flex: 1;
  }
}
