.card-preview {
  display: inline-block;
  position: relative;
  background: white;
  border-radius: .5rem;
  box-shadow: 1px 2px 2px $neutral-8;
  margin: .6rem;
  height: 8rem;
  width: 8rem;
  text-align: center;
  overflow: hidden;
  color: $text-color;
  contain: strict;

  > .flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: .4rem;
    overflow: hidden;

    > .front {
      @include rich-text;

      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;

      p {
        margin-top: 0.1em;
        margin-bottom: 0.1em;
      }
    }
  }

  // Hover states
  transition: transform 0.2s;
  box-sizing: border-box;

  &:hover {
    border: $border-width solid $highlight-6;
    transform: scale(1.07);
  }
}
