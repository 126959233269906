.popup-panel {
  background: white;
  padding: 1rem;

  & .close {
    position: absolute;
    right: 0;
    top: 0;
  }
}
