fieldset {
  border: 1px solid #bbb;
  border-radius: 0.5rem;
  padding: 1rem;

  legend {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
