.sync-details {
  & > * {
    margin-top: 0.5rem;
  }

  & > .progress {
    margin: 1rem auto;
  }
  & > .server {
    margin-top: 1.5rem;
    margin-left: auto;
    margin-right: auto;
  }
}
