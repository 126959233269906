span.icon {
  display: inline-block;
  width: 1em;
  height: 1em;

  background-image: url('img/icons.svg');
  background-position-y: -1em * $icon-row-highlight-5;
  background-size: $icon-columns+em $icon-rows+em;
  background-repeat: no-repeat;

  &.-settings {
    background-position-x: -14em;
  }
  &.-tag {
    background-position-x: -17em;
  }
  &.-key {
    background-position-x: -18em;
  }
  &.-grey {
    background-position-y: -1em * $icon-row-neutral-6;
  }
}
