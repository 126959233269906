.editnote-form {
  .header {
    display: flex;
    align-items: center;
    // Allow the menu to appear first in source order so that we can skip
    // tabbing through it when moving between the keywords and body.
    flex-direction: row-reverse;

    & > .keywords, & > .heading {
      flex: 1;
    }
  }

  .keywords {
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    > .icon {
      margin-left: .9rem;
    }

    > .tokens {
      flex: 1;
      padding-right: .2rem;
    }

    > .icon {
      background-position-y: -1em * $icon-row-yellow-4;
    }

    > .icon, > .tokens > .textentry {
      opacity: 0.7;
    }
    &:hover, &:focus-within {
      > .icon, > .tokens > .textentry {
        opacity: 1;
      }
    }
  }

  .menubutton {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
  }

  .heading {
    display: none;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem 0.2rem;
  }

  .body {
    border-top: 1px dotted $yellow-4;
  }

  &.-nomatch:not(.-hideeditcontrols) .body,
  &.-nomatch:focus-within .body {
    opacity: 0.5;
  }

  &.-nokeywords:not(.-hideeditcontrols) .body,
  &.-nokeywords:focus-within .body {
    opacity: 0.5;
    filter: hue-rotate(310deg);
  }

  .content {
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    min-height: 3rem;
    font-size: 1.2rem;
    cursor: text;

    color: lighten($text-color, 20%);
    &:hover, &:focus-within {
      color: $text-color;
    }

    .public-DraftEditorPlaceholder-root {
      color: $yellow-4;
      opacity: 0.7;
    }

    &:hover .public-DraftEditorPlaceholder-root {
      opacity: 1;
    }

    .public-DraftEditorPlaceholder-hasFocus {
      display: none;
    }
  }

  .controls {
    display: flex;
    padding: 0 .7rem .5rem;
  }

  @keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  .status {
    font-size: 0.8rem;
    font-style: italic;
    padding: 0.5rem 1rem;
  }

  &.-hideeditcontrols:not(:focus-within) {
    .header {
      .keywords {
        display: none;
      }

      > .heading {
        display: block;
      }
    }

    .body {
      border-top-color: transparent;

      > .content {
        padding-bottom: 0;
      }
      > .controls {
        padding-top: 0;

        > .delete {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .controls {
      opacity: 0;
    }
  }

  &.-hideeditcontrols .controls {
    transition: opacity .5s;
  }

  > .savestate {
    margin: 0.2rem 0.4rem;
    opacity: 0.5;
  }
}
