.summary-panel {
  background: $neutral-10;
  border-radius: .4rem;
  padding: 1rem;
  box-sizing: border-box;

  @include vertical-center;
  align-content: center;

  $icon-size: 5rem;

  & > * {
    margin-left: auto;
    margin-right: auto;
  }
  & > .icon {
    display: block;
    height: $icon-size;
    width: $icon-size;

    // Generic icons
    &.-general {
      background-image: url('img/icons.svg');
      background-size: ($icon-columns * $icon-size) ($icon-rows * $icon-size);
      background-repeat: no-repeat;
      background-position-y: -($icon-row-highlight-5 * $icon-size);
    }
    &.-general.-review {
      background-position-x: -(8 * $icon-size);
    }
    &.-general.-reviewfinished {
      background-position-x: -(16 * $icon-size);
    }

    // Sync-themed icons
    &.-sync {
      background-image: url('img/sync-icons.svg');
      background-size: ($num-sync-icons * $icon-size) $icon-size;
      background-repeat: no-repeat;
    }
    &.-sync.-notconfigured {
      background-position-x: -(5 * $icon-size);
    }
    &.-sync.-uptodate,
    &.-sync.-inprogress {
      background-position-x: -(6 * $icon-size);
    }
    &.-sync.-inprogress {
      animation: rotate 1s linear infinite;
    }
    &.-sync.-paused {
      background-position-x: -(7 * $icon-size);
    }
    &.-sync.-offline {
      background-position-x: -(8 * $icon-size);
    }
    &.-sync.-error {
      background-position-x: -(9 * $icon-size);
    }
  }
  & > .heading {
    text-align: center;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem; // Reset UA styles
  }
  & > .subheading {
    text-align: center;
    margin-top: 1rem;
  }
  & > .details {
    margin-top: 1.5rem;
    max-width: 100%;
  }
}
