.tab-block {
  position: relative;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;

  // Reset list styles
  list-style: none;
  padding: 0;
  margin: 0;

  &.-white {
    background: white;
  }

  & > .highlight-bar {
    position: absolute;
    left: 0;
    top: 0;

    height: $border-width * 2;
    background-color: $highlight-5;
    transition: transform 0.15s;
    transform: scale(0, 0) translate(0%);
    will-change: transform;
  }
}

.tab-item {
  flex: 1;
  text-align: center;
  min-height: 45px;

  border-top: $border-width * 2 solid transparent;

  @include vertical-center;

  & > * {
    height: 100%;
  }

  // Unselected links should be colored dark
  &:not(.-active) > a {
    color: $text-color;
    &.-icon::before {
      background-position-y: -1rem * $icon-row-text-color;
    }
  }

  &:focus-within {
    border-color: $neutral-6;
  }
  &:hover {
    border-color: $neutral-7;
  }
  &.-active > a {
    color: $highlight-5;
  }
  &.-active:focus-within > a {
    color: $highlight-6;
  }
  &.-active:hover > a {
    color: $highlight-7;
  }
  // Make the highlight bar also go brighter
  &.-active:focus-within ~ .highlight-bar {
    background-color: $highlight-6;
  }
  &.-active:hover ~ .highlight-bar {
    background-color: $highlight-7;
  }

  // Turn off outlines on links since it looks silly on touchscreen devices and
  // I haven't found a good way to detect when it looks silly and when it
  // doesn't.
  a:focus {
    outline: 0;
  }
}
