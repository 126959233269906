.pop-up {
  position: absolute;
  height: 100%;
  top: 0; // Needed for WebKit

  &[hidden] {
    // Override UA display: none style for hidden elements since we use
    // translation and opacity to hide the different components.
    display: block;
    pointer-events: none;
  }

  & > .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgb(220, 220, 220);
    transition: opacity 0.15s;
    opacity: 0.8;
  }
  &[hidden] > .overlay {
    opacity: 0;
  }

  & > .content {
    pointer-events: auto;

    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    box-sizing: border-box;
    width: calc(100vw - 1rem);
    height: auto;
    //
    // Without this max-height, we won't get scrollbars when the screen is too
    // small to fit the whole panel (e.g. when the onscreen keyboard is in use)
    //
    max-height: calc(100% - 1rem);
    overflow: auto;

    box-shadow: 0 0 6px grey;

    transform: translate(0%);
    transition: transform 0.15s ease-out;
  }

  &[hidden] > .content {
    transform: translate(0%, -120%);
    transform: translate(0%, calc(-100% - 80px));
    transition-timing-function: ease;
  }
}
