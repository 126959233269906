.note-frame {
  box-sizing: border-box;
  box-shadow: 0px 1px 3px $neutral-8;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: auto 1rem;
    grid-template-rows: 1rem;
    border-radius: 0 1.6rem 0 0;

    > .header,  > .cornerfiller {
      background: $yellow-8;
    }

    > .header {
      grid-row-end: span 2;
    }

    > .corner {
      width: 1rem;
      height: 1rem;
    }

    .body {
      background: $yellow-8;
      grid-column-end: span 2;
    }
  }

  @supports not (display: grid) {
    background: $yellow-8;
    padding-top: 1rem;

    > .header, > .corner, > .cornerfiller {
      display: none;
    }
  }
}
