.tricolor-progress {
  position: relative;

  // Set a default height. This will typically be overridden.
  height: 10px;

  > * {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    transition: transform 0.3s;
    transform-origin: 0% 50%;
  }

  > .a {
    background: $green-6;
  }
  > .b {
    background: $red-6;
  }
  > .c {
    background: $neutral-8;
  }
}
