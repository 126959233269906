.color-picker {
  display: grid;
  // The number of columns needs to be kept in sync with the |swatchesPerRow|
  // value in the ColorPicker component.
  grid-template-columns: repeat(3, 38px);
  column-gap: 10px;
  row-gap: 10px;

  > .swatch {
    background: var(--swatch-color);
    border-radius: 100%;
    outline-color: transparent;
    width: 35px;
    height: 35px;
    padding: 0;
    border: 1.5px solid $neutral-1;
    box-sizing: border-box;
    transition: transform 0.15s;
    will-change: transform;

    &::before {
      content: "";
      display: inline-block;
      position: relative;
      border-radius: 100%;
      border-width: 1px;
      width: 35px;
      height: 35px;
      margin-top: -1.5px;
      margin-left: -1.5px;
      opacity: 0.3;
      background: var(--swatch-color);
      transition: transform 0.2s;
      will-change: transform;
      transform: scale(1);
    }

    &.-selected::before {
      transform: scale(1.3);
    }
    &:hover {
      transform: scale(1.1);
    }
    &:focus {
      border-style: dashed;
      filter: brightness(1.1);
    }
    :focus:not(:focus-visible) {
      border-color: transparent;
    }
  }
}
