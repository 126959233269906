.addnote-button {
  color: $yellow-4;
  border-color: transparent;
  border-width: $border-width;
  border-radius: 0;
  padding: 0;
  background: transparent;
  position: relative;
  will-change: transform, opacity;
  -webkit-tap-highlight-color: transparent;

  > .front {
    > .toprow > .top, > .body {
      background: $yellow-8;
    }

    > .toprow {
      width: 100%;
      display: flex;

      > .top {
        flex: 1;
        will-change: transform;
      }

      > .corner {
        width: 1em;
        height: 1em;
        will-change: transform;
      }
    }

    > .body {
      padding-left: 3.2em;
      padding-right: 2em;
      padding-bottom: 1em;
      will-change: transform;

      > .label {
        display: flex;
        align-items: center;
        will-change: opacity;
      }

      > .label::before {
        content: "";
        display: inline-block;
        position: relative;

        width: 1em;
        height: 1em;

        background-image: url('img/icons.svg');
        background-position-x: -19em;
        background-position-y: -1em * $icon-row-yellow-4;
        background-size: $icon-columns+em $icon-rows+em;
        background-repeat: no-repeat;

        margin-left: -1.5em;
        margin-right: 0.5em;
      }
    }
  }

  > .shadow {
    position: absolute;
    z-index: -1;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0 1.7rem 0 0;

    background: rgba(0, 0, 30, 0.1);
    box-shadow: 0px 1px 3px $neutral-8;

    &.topright {
      box-shadow: none;
    }
  }

  &:focus, &:active {
    filter: none;
    color: #d3bf00;
    > .front {
      > .toprow > .top, > .body {
        background-color: #ffffd3;
      }
      > .body > .label::before {
        background-position-y: -1em * $icon-row-yellow-5;
      }
    }
  }

  &:hover {
    filter: none;
    color: #ddc800;
    > .front {
      > .toprow > .top, > .body {
        background-color: #ffffdd;
      }
      > .body > .label::before {
        background-position-y: -1em * $icon-row-yellow-5;
      }
    }
  }

  &:focus {
    outline: none;
  }
  &:-moz-focusring {
    border: $border-width dotted $yellow-4;
  }
  &:focus-visible {
    border: $border-width dotted $yellow-4;
  }

  &:active {
    > .front {
      transform: translateY(1px);
    }
  }
}
