.edit-screen {
  height: 100%;

  > .buttons {
    box-sizing: border-box;
    width: 100%;
    position: sticky;
    top: 0px;
  }

  > .form {
    max-width: 800px;
    margin: 1rem auto 0;
  }

  > .savestate {
    box-sizing: border-box;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.2rem 2rem;
    opacity: 0.5;
  }

  > .divider {
    width: 70%;
    margin-bottom: 1rem;
  }

  > .notes {
    width: 80%;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
}
