.editcard-form {
  box-sizing: border-box;

  @include card-styles;

  > * {
    box-sizing: border-box;
    width: 100%;
  }

  > .menubutton {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    float: right;
    margin-right: .4rem;
    margin-top: .4rem;
    margin-bottom: -2rem;
    position: relative;
  }

  > .tags, > .keywords {
    display: flex;
    border-top: 1px dotted transparent;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    > .icon {
      margin-top: 1rem;
      margin-left: .9rem;
    }

    > .tokens {
      flex: 1;
    }

    > .icon, > .tokens > .textentry {
      opacity: 0.7;
    }
    &:hover, &:focus-within {
      border-top: 1px dotted $neutral-6;
      > .icon, > .tokens > .textentry {
        opacity: 1;
      }
    }
  }

  > .keywords {
    background-color: $yellow-8;

    &:hover, &:focus-within {
      border-top-color: $yellow-4;
    }

    > .icon {
      background-position-y: -1em * $icon-row-yellow-4;
    }
  }
}
