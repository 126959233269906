.tool-bar {
  background: white;
  box-shadow: 0 2px 2px $neutral-8;
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  > * {
    flex: 1 0 0;
    display: flex;
    align-items: center;
  }

  // This shouldn't affect layout, but just make the hit region for these
  // elements reach to the full extent of the toolbar.
  a, button {
    height: 100%;
  }

  > .-center {
    flex: 0 0 auto;
  }

  > *:last-child {
    justify-content: flex-end;
  }
}
