input:not([type]),
input[type=text],
input[type=password],
input[type=search],
textarea {
  &.-compact {
    height: 2.5rem;
    padding-left: .3rem;
    padding-right: .3rem;
  }

  &.-rounded {
    border-radius: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    // Outline boxes in Chrome don't following the rounding and look really
    // stupid.
    outline-width: 0px;
  }

  &.-icon {
    background-position: 0.9rem;
    background-size: 1rem 1rem;
    background-repeat: no-repeat;
    padding-left: 2.5rem;
  }

  &.-icon.-search {
    background-image: url('img/icons.svg#search-grey');
  }
  &.-icon.-user {
    background-image: url('img/icons.svg#user-grey');
  }
  &.-icon.-lock {
    background-image: url('img/icons.svg#lock-grey');
  }
}
