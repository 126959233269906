@mixin textbox-styles {
  -webkit-appearance: none;
  background: #fff;
  background-image: none;
  border: $border-width solid $neutral-8;
  border-radius: .3em;
  color: $text-color;

  height: 3rem;
  padding: .4em .6em;

  &:hover {
    border-color: $neutral-6;
  }

  &:focus-within {
    border-color: $highlight-6;
    box-shadow: 0 0 .2rem .2rem $highlight-10;
  }

  &::placeholder {
    color: $neutral-7;
    opacity: 1;
  }
}
