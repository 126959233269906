.tab-panel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-color: $neutral-6 $neutral-9;
  background: $neutral-10;

  transition: opacity 0.1s ease;

  &[hidden] {
    opacity: 0;
    pointer-events: none;
    // This is so that when we are fading from one panel to another we don't
    // have a moment along the way where both panels are translucent and the
    // background shows through.
    transition-timing-function: step-end;

    // However, we shouldn't do this for the case when we are hiding all
    // panels (e.g. to return to the homescreen).
    &.-allhidden {
      // We should fade out this case too but currently when we delete a card,
      // we immediately update the view to say "Card deleted" and if you see
      // that while it's fading out it looks a bit odd. We'll probably fix that
      // by simply making it select the next card in the "working set" once we
      // introduce that concept, but for now we just make the transition snap
      // back to the home page.
      transition-timing-function: step-start;
    }

    // Revert browser display:none style
    display: block;
  }
}
